<template>
	<section class="carrier">

		<div class="carrier-bg">

			<div class="carrier__top-header">
				<router-link to="/">
					<img src="@/assets/images/logo_white.svg" alt="logo">
				</router-link>

				<Button
					buttonStyle="default"
					buttonLink="/kontakt"
					buttonText="Porozmawiajmy"
				></Button>
			</div>

			<div class="carrier__content">
				<div class="carrier__header">
					<h4 class="h4-style">dołącz do nas</h4>
					<h2 class="c-h2-style h2-sm-style">KARIERA</h2>

					<p class="c-p-style">
						Organizację tworzą ludzie, dlatego też stawiamy na kompetentny, zgrany zespół, który rozumie
						postawione przed nim wyzwania. W naszym obecnym modelu nie przewidujemy biura, do którego trzeba
						się
						zrywać każdego ranka, dlatego też poszukujemy osób, które dobrze czują się w modelu pracy
						zdalnej
						bądź pracy freelancera.
					</p>
					<span>Napisz do nas jeśli poszukujesz wyzwań i pracujesz jako:</span>
				</div>

				<section class="carrier__jobs">

					<div class="carrier__col">
						<ul class="carrier__list">
							<li class="carrier__list-item">
								<p>
									UX RESEARCHER
								</p>
							</li>
							<li class="carrier__list-item">
								<p>
									UX WRITER
								</p>
							</li>
							<li class="carrier__list-item">
								<p>
									UX DESIGNER
								</p>
							</li>
							<li class="carrier__list-item">
								<p>
									UI DESIGNER
								</p>
							</li>
						</ul>
					</div>
					<div class="carrier__col">
						<ul class="carrier__list">
							<li class="carrier__list-item">
								<p>
									COPYWRITER
								</p>
							</li>
							<li class="carrier__list-item">
								<p>
									ILUSTRATOR
								</p>
							</li>
							<li class="carrier__list-item">
								<p>
									MOTION DESIGNER
								</p>
							</li>
							<li class="carrier__list-item">
								<p>
									ANIMATOR 3D
								</p>
							</li>
						</ul>
					</div>
					<div class="carrier__col">
						<ul class="carrier__list">
							<li class="carrier__list-item">
								<p>
									FRONT-END DEV
								</p>
							</li>
							<li class="carrier__list-item">
								<p>
									BACK - END DEV
								</p>
							</li>
						</ul>
					</div>
				</section>

				<section class="requirements">
					<h3 class="c-h3-style">Oczekujemy</h3>

					<ul class="requirements__list">
						<li class="requirements__list-item">
							<p class="c-p-style">
								Posiadania przynajmniej dwóch lat doświadczenia w pracy na etacie
							</p>
						</li>
						<li class="requirements__list-item">
							<p class="c-p-style">
								Projektów komercyjnych, którymi możesz się pochwalić w portfolio
							</p>
						</li>
						<li class="requirements__list-item">
							<p class="c-p-style">
								Terminowości<br>
								i kontaktowości
							</p>
						</li>
						<li class="requirements__list-item">
							<p class="c-p-style">
								Umiejętności pracy<br>
								w zespole
							</p>
						</li>
						<li class="requirements__list-item">
						<span>
							Mile widziane
						</span>
							<p class="c-p-style">
								Co najmniej rok pracy
								na freelance
							</p>
						</li>
					</ul>
				</section>

				<section class="offer">
					<h3 class="c-h3-style">
						Oferujemy
					</h3>

					<div class="offer__list">
						<li class="offer__list-item">
							<p class="c-p-style">
								Pracę zdalną / freelance
							</p>
						</li>
						<li class="offer__list-item">
							<p class="c-p-style">
								Rozliczenie na B2B / UoD
							</p>
						</li>
						<li class="offer__list-item">
							<p class="c-p-style">
								Opiekę osoby zarządzającej (Product Ownera / Creative Directora / Art Directora)
							</p>
						</li>
					</div>
				</section>

				<div class="carrier__mission">
					<p class="c-p-style">
						Więcej o naszej misji możesz przeczytać
						<router-link to="/misja">tutaj</router-link>
						- jeżeli czujesz, że pasujesz, napisz do nas, koniecznie z załączonym portfolio!

					</p>

					<h3 class="c-h3-style">jobs@hylite.pl</h3>

					<span class="clause">
					W treści maila zawrzyj proszę klauzulę:<br><br>

Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do realizacji procesu rekrutacji zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO). Wyrażam również zgodę na przetwarzanie moich danych osobowych w celu prowadzenia przyszłych rekrutacji przez wyżej wskazaną firmę.
				</span>
				</div>

			</div>
		</div>
		<FAQComponent currentButton="produkt"/>
	</section>
</template>

<script>
import FAQComponent from '@/components/FAQComponent/index'


export default {
	name: "index carrier",
	components: {FAQComponent},
}
</script>

<style src="./style.sass" scoped lang="sass"/>

<style scoped lang="sass">
.c-h2-style
	font-size: 62px
	font-weight: $weight--semi-bold
	margin-bottom: 50px

.c-h3-style
	font-size: 33px
	font-weight: $weight--semi-bold
	margin-bottom: 42px

	@include view('sm')
		font-size: 28px

.c-p-style
	font-size: 15px
	font-weight: $weight--light-plus
	line-height: 22px
	color: $color--gray

::v-deep .main__faq
	@include view('sm')
		padding: 60px 33px
</style>
